import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

  private user:any;

  constructor(private router:Router,private userService:UsersService) { }

  async canActivate(){    
    //utilisateur déconnecté
    if(!localStorage.getItem('user') || !localStorage.getItem('jwt')){
      localStorage.clear();
      this.router.navigate(['/']);
      return false;
    }


    //récupere mes informations utilisateurs
    this.user = JSON.parse(localStorage.getItem('user') || "{}");

    //Vérification du statut d'aministration
    if(this.user.is_admin) return true;
    else{
      this.router.navigate(["/app/navigation"]);
      return false;
    }
  }
}
