import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SuperGlobal } from '../../class/super-global';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private env: any;

  constructor(private http:HttpClient) {
  }


  updateIsShown (messageId,status) {
    return new Promise((resolve,reject) => {
      this.http
      .put(
        '/api/message/isShown',
        {"messageId" : messageId , "status" : status},
        { headers: SuperGlobal.get_req_header() }
      )
      .toPromise()
      .then(async (response: any) => {
        resolve(response);
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403) SuperGlobal.disconnect_user();
        else resolve(err)
      });
    })
  }

  like (messageId) {
    return new Promise((resolve,reject) => {
      this.http
      .post(
        '/api/message/like',
        {"messageId" : messageId},
        { headers: SuperGlobal.get_req_header() }
      )
      .toPromise()
      .then(async (response: any) => {
        resolve(response);
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403) SuperGlobal.disconnect_user();
        else resolve(err)
      });
    })
  }

  getLikeForUser () {
    return new Promise((resolve,reject) => {
      this.http
      .get(
        '/api/message/like/user',
        { headers: SuperGlobal.get_req_header() }
      )
      .toPromise()
      .then(async (response: any) => {
        resolve(response);
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403) SuperGlobal.disconnect_user();
        else resolve(err)
      });
    })
  }


  send (message : any, pseudo : any) {
    return new Promise((resolve,reject) => {
      this.http
      .post(
        '/api/message/',
        {"message" : message , "pseudo" : pseudo},
        { headers: SuperGlobal.get_req_header() }
      )
      .toPromise()
      .then(async (response: any) => {
        resolve(response);
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403) SuperGlobal.disconnect_user();
        else resolve(err)
      });
    })
  }

  getForUser () {
    return new Promise((resolve,reject) => {
      this.http
      .get(
        '/api/message/user/',
        { headers: SuperGlobal.get_req_header() }
      )
      .toPromise()
      .then(async (response: any) => {
        resolve(response);
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403)
          SuperGlobal.disconnect_user();
      });
    })
  }

  getWithOffset (offset : any,isShown : any,order : any) {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/message/pagination/'+offset+"/"+isShown+"/"+order,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }

  count (isShown : any) {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/message/count/'+isShown,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }
}
