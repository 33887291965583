import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NotificationService } from './shared/service/notification/notification.service';
import { UsersService } from './shared/service/users/users.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  
  constructor(public notificationService:NotificationService,private userService:UsersService,private swUpdate: SwUpdate) {
    //#region Check for updates
    if (this.swUpdate.isEnabled) {
      this.swUpdate.activated.subscribe((upd) => {
        window.location.reload();
      });
      this.swUpdate.available.subscribe((upd) => {
        this.swUpdate.activateUpdate();
      }, (error) => {
        console.error(error);
      });
      this.swUpdate.checkForUpdate().then(() => {
      }).catch((error) => {
        console.error('Could not check for app updates', error);
      });
    }
    //#endregion
  }

  async ngOnInit(){
    //si j'ai le JWT mais pas l'utilisateur 
    if(!localStorage.getItem("user") && localStorage.getItem("JWT")){
      //récuperes les informations utilisateur
      await this.userService.get_user_by_jwt();
      location.reload(); // reload pour mettre a jour l'interface avec les données utilisateurs
    }

    //Si j'ai l'utilisateur ou le JWT
    if(localStorage.getItem("user") || localStorage.getItem("JWT")) {
        // récupere les informations utilsiateurs et met a jours la date de derniere connexion
        await this.userService.get_user_by_jwt(); 
    } 

      // Enregistre le service worker des notifications 
      await this.notificationService.registerServiceWorker();


      if(Notification.permission === "default") {
        let resp = await this.notificationService.activerNotification();
        
       if(resp) {
          location.reload()
        }
      }
  }
}
