import { HttpClient , HttpErrorResponse , HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public env:any;
  private reqHeader:any;
  
  public navigation:any;

  constructor(private http:HttpClient) { 
    this.env = this.setENV();
    //init req header
    if(localStorage.getItem('jwt')){
      this.reqHeader = new HttpHeaders({
        'Content-Type' : 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem('jwt'),
      });
    }
  }


  async save(navigation){
    return new Promise((resolve,reject)=>{
      this.http.post('/api/navigation/save',navigation,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve("");
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async get_navigation(){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/navigation/get_all',{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        this.navigation = response;
        resolve(this.navigation);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  //return pages who are not currently in navigation
  async get_available_pages(pages){
    return new Promise((resolve,reject)=>{
      let available_pages:any = [];
      let verif:any;

      for(let page of pages){
        verif=true;
        for(let nav_item of this.navigation){
          if(page.id == nav_item.id){
            verif=false;
          }
        }

        if(verif){
          available_pages.push(page)
        }
      }

      resolve(available_pages)
    })
  }


  async update_navigation_showed () {
    return new Promise((resolve,reject)=>{
      this.http.put('/api/users/navigation_showed',[],{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve("");
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }



  setENV() {
    if (window.location.hostname.indexOf("app") != -1) {
      return "app";
    } else if (window.location.hostname.indexOf("test") != -1) {
      return "test";
    } else if (window.location.hostname.indexOf("localhost") != -1) {
      return "local";
    } 
  }

  async disconnect_user(){
    localStorage.clear();
    window.location.href = "/accueil";
  }
}
