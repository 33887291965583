import { HttpClient ,HttpErrorResponse , HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesCustomService {

  private env:any;
  private reqHeader:any;
  private user:any;
  private activities_custom:any;

  constructor(private http:HttpClient,private router:Router) {
    this.env = this.setENV();
    //init req header
    if(localStorage.getItem('jwt')){
      this.reqHeader = new HttpHeaders({
        'Content-Type' : 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem('jwt'),
      });
    }
   }



   async get(){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/activities_custom/',{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        this.activities_custom = response;
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async get_by_id(id:any){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/activities_custom/'+id,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }


  async create(activities_custom : any){
    return new Promise((resolve,reject)=>{
      this.http.post("/api/activities_custom/",activities_custom,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();

        if(err.status == 400) resolve(false);
      })
    })
  }

  

  async update(activities_custom : any){
    return new Promise((resolve,reject)=>{
      this.http.put("/api/activities_custom/",activities_custom,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();

        if(err.status == 400) resolve(false);
      })
    })
  }

  async convert_scss_to_css(scss:any){
    return new Promise((resolve,reject)=>{      
      this.http.post("/api/activities_custom/special/scss_to_css/",{"scss":scss}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        console.log(err)
      })
    })
  }

  async disconnect_user(){
    localStorage.clear();
    alert("activities_custom");
    window.location.href = "/navigation";
  }

  setENV() {
    if (window.location.hostname.indexOf("app") != -1) {
      return "app";
    } else if (window.location.hostname.indexOf("test") != -1) {
      return "test";
    } else if (window.location.hostname.indexOf("localhost") != -1) {
      return "local";
    } 
  }
}
