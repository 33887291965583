import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import { SuperGlobal } from 'src/app/shared/class/super-global';
import { PagesService } from 'src/app/shared/service/pages/pages.service';
import { AlertController, PopoverController } from '@ionic/angular';
import { NotificationController } from 'src/app/shared/class/notification-controller';
import { NotificationService } from 'src/app/shared/service/notification/notification.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public user: any;
  public nav: any; //Contien un élement du DOM
  public nav_item_selected: any;
  public pages_accomplissement:any;

  public notificationStatus : any;

  constructor(public toastController : ToastController,public notificationService:NotificationService,public alertController:AlertController,private pageService:PagesService,private route: ActivatedRoute, private router: Router) {}

  async ngOnInit() {
    //recupere l'utilisateur courrant si il est connecté
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user') || '{}');
    } else return;

    this.nav = document.getElementById('header-nav');



    // Gestion affichage des boutons
    let interval = setInterval(()=>{
      let verif_loading = document.getElementById('header-hamburgeur');

      if(verif_loading) {
        this.gestion_bouton();
        clearInterval(interval);
      }
    },10)
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        this.gestion_affichage_mascotte();
        this.gestion_bouton();
      }
    });

    this.init_accomplissement_mascotte();
    
    this.notificationStatus = await this.notificationService.getNotificationStatus();  
  }

  revoirIntroAlert() {
    let revoirIntro = document.getElementById('revoir-intro');
    let revoirIntroMasque = document.getElementById('revoir-intro-masque');

    revoirIntro.style.visibility = "visible";
    revoirIntroMasque.style.visibility = "visible";

    revoirIntro.style.opacity = "1";
    revoirIntroMasque.style.opacity = "0.5";
  }

  revoirIntro () {
    this.masquerRevoirIntro();

    this.router.navigate(['/app/page-premiere-connexion'])
  }

  popupRedirection () {
    let popupRedirection = document.getElementById('popup-redirection');
    let revoirIntroMasque = document.getElementById('revoir-intro-masque');

    popupRedirection.style.visibility = "visible";
    revoirIntroMasque.style.visibility = "visible";

    popupRedirection.style.opacity = "1";
    revoirIntroMasque.style.opacity = "0.5";
  }

  masquerPopupRedirection () {
    let popupRedirection = document.getElementById('popup-redirection');
    let revoirIntroMasque = document.getElementById('revoir-intro-masque');

    popupRedirection.style.visibility = "hidden";
    revoirIntroMasque.style.visibility = "hidden";

    popupRedirection.style.opacity = "0";
    revoirIntroMasque.style.opacity = "0";
  }

  handleClickOnMasque () {
    if(document.getElementById('revoir-intro')) {
      this.masquerRevoirIntro();
    }
    if(document.getElementById('popup-deconnexion')) {
      this.masquerDeconnexion();
    }
    this.masquerPopupRedirection();
  }

  masquerRevoirIntro() {
    let revoirIntro = document.getElementById('revoir-intro');
    let revoirIntroMasque = document.getElementById('revoir-intro-masque');

    revoirIntro.style.visibility = "hidden";
    revoirIntroMasque.style.visibility = "hidden";

    revoirIntro.style.opacity = "0";
    revoirIntroMasque.style.opacity = "0";
  }

  masquerDeconnexion () {
    let popupDeconnexion = document.getElementById('popup-deconnexion');
    let revoirIntroMasque = document.getElementById('revoir-intro-masque');

    popupDeconnexion.style.visibility = "hidden";
    revoirIntroMasque.style.visibility = "hidden";

    popupDeconnexion.style.opacity = "0";
    revoirIntroMasque.style.opacity = "0";
  }

  async activerNotification () {
    this.notificationStatus = true;
    let resp = await this.notificationService.activerNotification();

    // Afficher un toast pour dire que les notifications sont désactivé
    if(!resp) {
      this.notificationStatus = false;

      const toast = await this.toastController.create({
        message : "Les notifications sont désactivé sur votre navigateur",
        duration : 3000,
        cssClass : "toast-notification-desactive"
      });
      toast.present();
    }
  } 

  desactiverNotification () {
    this.notificationService.desactiverNotification();
    this.notificationStatus = false;
  }



  gestion_bouton () {
    const buttonHamburger: any = document.getElementById('header-hamburgeur');
    const buttonDeconnexion : any = document.getElementById('header-deconnexion');
    const buttonAdmin : any = document.getElementById('header-admin');
    // const btnNotification : any = document.getElementById('btn-notification');

    // Si je suis sur navigation alors j'affiche la déconnexion
    if (location.href.includes('app/navigation')) {
      buttonHamburger.style.display = 'none';
      buttonDeconnexion.style.display = 'block';
      // btnNotification.style.display = "block";
    }
    // Sinon j'affiche le bouton hamburger
    else {
      buttonHamburger.style.display = 'block';
      buttonDeconnexion.style.display = 'none';
    }

    if(location.href.includes("admin")) {
      // btnNotification.style.display = "none";
      buttonAdmin.style.display = "none";
    } 
    else if(document.getElementById('header-admin')) buttonAdmin.style.display = "block"
  }

  gestion_affichage_mascotte () {
    let mascotte :any = document.getElementById('header-mascotte');

    let hideMascotte = ['app/introduction',"app/page-premiere-connexion","accueil"];


    let show = "flex";
    for (let page of hideMascotte) {
      if (location.href.includes(page) && !location.href.includes('accueil-du-stagiaire')) {
        show = "none";
      }
    }
    mascotte.style.display = show;
  }

  async init_accomplissement_mascotte () {
    this.pages_accomplissement = await this.pageService.get_accomplissement();
    let mascotte :any = document.getElementById('header-mascotte');

    let mascotte_accomplissement_count = 0;
    for (let accomplissement of this.pages_accomplissement) {
      if(accomplissement.mascotte_count) mascotte_accomplissement_count++;
    }

    mascotte.src = "assets/pictures/mascotte-header/toques_"+mascotte_accomplissement_count+".png"
  }

  deconnexionAlert () {
    let popupDeconnexion = document.getElementById('popup-deconnexion');
    let revoirIntroMasque = document.getElementById('revoir-intro-masque');

    popupDeconnexion.style.visibility = "visible";
    revoirIntroMasque.style.visibility = "visible";

    popupDeconnexion.style.opacity = "1";
    revoirIntroMasque.style.opacity = "0.5";
  }

  deconnexion() {
    localStorage.clear();
    location.reload();
  }
}
