import { HttpClient ,HttpErrorResponse , HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NavigationService } from '../navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class ParametresService {

  private env:any;

  constructor(private http:HttpClient,private router:Router,private navigationService:NavigationService) {
    this.env = this.setENV();
    
   }

   get_reqHeader(){
    //init req header
    if(localStorage.getItem('jwt')){
      return new HttpHeaders({
        'Content-Type' : 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem('jwt'),
      });
    }
   }

   async get_page_premiere_connexion(){
    return new Promise((resolve,reject)=>{

      this.http.get('/api/parametres/page_premiere_connexion/',{headers : this.get_reqHeader()}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }
  
  
  async set_page_premiere_connexion(id){
    return new Promise((resolve,reject)=>{
      this.http.post('/api/parametres/page_premiere_connexion/',id,{headers : this.get_reqHeader()}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

   
  async disconnect_user(){
    localStorage.clear();
    window.location.href = "/accueil";
  }

  setENV() {
    if (window.location.hostname.indexOf("app") != -1) {
      return "app";
    } else if (window.location.hostname.indexOf("test") != -1) {
      return "test";
    } else if (window.location.hostname.indexOf("localhost") != -1) {
      return "local";
    } 
  }
}
