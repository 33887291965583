import { HttpHeaders } from "@angular/common/http";

export class SuperGlobal {

    static get_req_header() : HttpHeaders{
        return(new HttpHeaders({
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('jwt'),
        }))
    }


    static get_JWT(){
        return new Promise((resolve,reject)=>{
            if(localStorage.getItem('jwt')) resolve(localStorage.getItem('jwt'));
            else resolve("");
        })
    }

    static disconnect_user(){
        alert('Vous avez été déconnecté');
        localStorage.clear();
        location.href = "/accueil"
    }

    static hexToRGB(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);
    
        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
      }

    static urlBase64ToUint8Array(base64String) {
        const padding = "=".repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, "+")
            .replace(/_/g, "/");
        
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
    
}
