import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthGuard } from './shared/service/authGuard/auth-guard.service';
import { UsersService } from './shared/service/users/users.service';
import { PagesService } from './shared/service/pages/pages.service';
import { NavigationService } from './shared/service/navigation/navigation.service';
import { GlobalMessageComponent } from './components/global-message/global-message.component';
import { AdminModule } from './modules/admin/admin.module';
import { ActivitiesService } from './shared/service/activities/activities.service';
import { ActivitiesCustomService } from './shared/service/activities_custom/activities-custom.service';
import { SafeHtmlPipe } from './shared/pipe/safe-html.pipe';
import { ParametresService } from './shared/service/parametres/parametres.service';
import { ActivitiesQuizService } from './shared/service/activities_quiz/activities-quiz.service';
import { NotificationService } from './shared/service/notification/notification.service';
import { MessagesService } from './shared/service/messages/messages.service';
import { CheckListComponent } from './components/logged/check-list/check-list.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    GlobalMessageComponent,
    CheckListComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    NavigationService,
    AuthGuard,
    UsersService,
    PagesService,
    ActivitiesService,
    ActivitiesCustomService,
    ParametresService,
    ActivitiesQuizService,
    NotificationService,
    MessagesService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
