import { HttpClient ,HttpErrorResponse , HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SuperGlobal } from '../../class/super-global';
import { ParametresService } from '../parametres/parametres.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public test = false;

  private MOODLE_TOKEN = "c8e35b164d00b4af8cf92a0a9b6e497c";
  private env:any;
  private reqHeader:any;

  constructor(private http:HttpClient,private router:Router,private parametresService:ParametresService) {

    //init req header
    if(localStorage.getItem('jwt')){
      this.reqHeader = new HttpHeaders({
        'Content-Type' : 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem('jwt'),
      });
    }
   }


   //retournes les informations de mon utilisateurs a partir de son JWT / met a jours les informations depuis moodle
   // et met a jours la date de derniere connexion
   async get_user_by_jwt(){
    return new Promise(async (resolve,reject)=>{
      this.http.get('/api/users/',{headers: SuperGlobal.get_req_header()}).toPromise().then(async (response:any)=>{
        localStorage.setItem('user',JSON.stringify(response));
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) SuperGlobal.disconnect_user();
      })
    })
  }

   async get_all_users(){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/users/get_all_users/',{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  get_last_created () {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/users/last_created/',
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }


  getWithOffset (offset : any) {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/users/pagination/'+offset,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }

  count () {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/users/count/',
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }

  async get_all_admin(){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/users/get_all_admin/',{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async get_moodle_user_by_id(id_moodle:any){
    return new Promise((resolve,reject)=>{
      let moodle_function = "&wsfunction=core_user_get_users_by_field";
      let moodle_format = "&moodlewsrestformat=json"
      let moodle_field = "&field=id&values[0]="+id_moodle;

      this.http.get(environment.moodle_api_url+"?wstoken="+this.MOODLE_TOKEN+moodle_function+moodle_format+moodle_field).toPromise().then(async (response:any)=>{
        if(response.errorcode){
          console.log(response)
          resolve("error");
          return;
        } 
        if(response.length == 0){
          resolve(null)
          return;
        }
        //retourne mon utilisateur
        else resolve(response[0]);  
      }).catch(async (err: HttpErrorResponse)=>{
        console.log(err)
      });
    })
  }



  async get_enrolled_users(course_id:any){
    return new Promise((resolve,reject)=>{
      let moodle_function = "&wsfunction=core_enrol_get_enrolled_users";
      let moodle_format = "&moodlewsrestformat=json"
      let moodle_field = "&courseid="+course_id;

      this.http.get(environment.moodle_api_url+"?wstoken="+this.MOODLE_TOKEN+moodle_function+moodle_format+moodle_field).toPromise().then(async (response:any)=>{
        if(response.errorcode){
          console.log(response)
          resolve("error");
          return;
        } 
        if(response.length == 0){
          resolve(null)
          return;
        }
        //retourne mon utilisateur
        else resolve(response);  
      }).catch(async (err: HttpErrorResponse)=>{
        console.log(err)
      });
    })
  }


  async update_user_last_access(){
    return new Promise(async (resolve,reject)=>{
      this.http.post('/api/users/update_user_last_access/',[],{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(null);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  

  async verif_is_admin_by_jwt(){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/users/is_admin/',{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async verif_jwt(){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/users/verif_jwt/',{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async disconnect_user(){
    localStorage.clear();
    alert("users");
    window.location.href = "/navigation";
  }
}
