import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SuperGlobal } from '../../class/super-global';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private env: any;
  private reqHeader: any;

  public worker: ServiceWorkerRegistration;
  public subscription: PushSubscription;

  constructor(private http: HttpClient, private router: Router) {

    //init req header
    if (localStorage.getItem('jwt')) {
      this.reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('jwt'),
      });
    }
  }


  envoyerNotification (utilisateurs:any,titre:any,message:any,dateEnvoi:any,redirectionUrl:any) {
    return new Promise((resolve,reject) => {
      this.http
        .post(
          '/api/notification/',
          {"utilisateurs":utilisateurs,"titre":titre,"message":message,"dateEnvoi":dateEnvoi,"redirectionUrl":redirectionUrl},
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();

          if(err.status == 400) {
            resolve(err.message)
          }
        });
    })
  }

  get (id :any) {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/notification/unique/'+id,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }

  getWithOffset (offset : any) {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/notification/pagination/'+offset,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }
  
  getUserWithOffset (id : any,offset : any) {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/notification/unique/pagination/'+id+'/'+offset,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }

  count () {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/notification/count/',
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }

  countRecepteur (id:any) {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/notification/count/recepteur/'+id,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }

  getUserSubscriptionByNumberModuleFinished ( number : any ) {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/notification_subscription/get_by_number_module_finished/'+number,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }

  getUserSubscriptionByDateLastConnection ( timestamp : any ) {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/notification_subscription/get_by_date_last_connection/'+timestamp,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  } 

  getNombreInscrit () {
    return new Promise((resolve,reject) => {
      this.http
        .get(
          '/api/notification_subscription/nombre_subscription/',
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    })
  }

  getNotificationStatus() {
    return new Promise((resolve, reject) => {
      let interval = setInterval(async () => {
        if (this.worker) {
          clearInterval(interval);

          this.subscription = await this.worker.pushManager.getSubscription();
          resolve(this.subscription);
        }
      }, 20);
    });
  }

  async desactiverNotification() {
    await (await this.worker.pushManager.getSubscription()).unsubscribe()
  }

  async activerNotification() {
    return new Promise((resolve,reject) => {
      Notification.requestPermission().then(async (permission) => {
        // Si les notifications sont accepté j'enregistre le device
        if (permission === 'granted') {
          const subscription = await this.worker.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: SuperGlobal.urlBase64ToUint8Array(
              environment.pushPrivateKey
            ),
          });
          // enregistre l'enregistrement device en BDD
          resolve(await this.saveSubscription(subscription))
        } else {
          resolve(false)
        }
      });
    })
  }

  async registerServiceWorker() {
    if (
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      'Notification' in window
    ) {
      // enregiste ou met a jours le service worker de notification
      await navigator.serviceWorker
        .register(environment.workerUrl)
        .then((reg) => {
          reg.update();
          this.worker = reg;
        });
    } else {
      console.log('Notification non prises en compte');
    }
  }

  async removeSubscription(subscription: any) {
    return new Promise(async (resolve, reject) => {
      this.http
        .post(
          '/api/notification_subscription/delete/',
          [+JSON.stringify(subscription)],
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    });
  }

  async saveSubscription(subscription: any) {
    return new Promise(async (resolve, reject) => {
      this.http
        .post(
          '/api/notification_subscription/save',
          [JSON.stringify(subscription)] ,
          { headers: SuperGlobal.get_req_header() }
        )
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status == 401 || err.status == 403)
            SuperGlobal.disconnect_user();
        });
    });
  }
}
