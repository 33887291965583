import { HttpClient ,HttpErrorResponse , HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NavigationService } from '../navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  private env:any;
  private reqHeader:any;
  private user:any;
  private pages:any;

  constructor(private http:HttpClient,private router:Router,private navigationService:NavigationService) {
    this.env = this.setENV();
    //init req header
    if(localStorage.getItem('jwt')){
      this.reqHeader = new HttpHeaders({
        'Content-Type' : 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem('jwt'),
      });
    }
   }

   async get_accomplissement () {
    return new Promise((resolve,reject)=>{
      this.http.get('/api/pages_accomplissement/',{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
   }

   async get_accomplissement_for_page (page_id:any) {
    return new Promise((resolve,reject)=>{
      this.http.get('/api/pages_accomplissement/'+page_id,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        if(!response) {
          resolve("false")
        }
        else resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
   }

   async create_accomplissement (page_id:any) {
    return new Promise((resolve,reject)=>{
      this.http.post('/api/pages_accomplissement/',[page_id],{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
   }

   async get_all_pages(){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/pages/get_all_pages/',{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        this.pages = response;
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async create(page){
    return new Promise((resolve,reject)=>{
      this.http.post('/api/pages/create/',page,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        this.pages = response;
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();

        if(err.status == 400) resolve(false);
      })
    })
  }

  async get_page(id_page){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/pages/get/'+id_page,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async get_page_by_slug(slug){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/pages/get_by_slug/'+slug,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }


  async update(page){
    return new Promise((resolve,reject)=>{
      this.http.put("/api/pages/update/",page,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async add_activities(activities:any,page_id:any){
    return new Promise((resolve,reject)=>{
      this.http.post("/api/pages_activities/",[activities,page_id],{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async get_activities(id_page:any){
    return new Promise((resolve,reject)=>{
      this.http.get("/api/pages_activities/"+id_page,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async delete(page){
    return new Promise((resolve,reject)=>{
      this.http.delete("/api/pages/delete/"+page.id,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }






  async disconnect_user(){
    localStorage.clear();
    alert("pages");
    window.location.href = "/navigation";
  }

  setENV() {
    if (window.location.hostname.indexOf("app") != -1) {
      return "app";
    } else if (window.location.hostname.indexOf("test") != -1) {
      return "test";
    } else if (window.location.hostname.indexOf("localhost") != -1) {
      return "local";
    } 
  }
}
