import { Component, Input, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem} from '@angular/cdk/drag-drop';
import { CdkDragEnd, CdkDragStart, CdkDragMove } from '@angular/cdk/drag-drop';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';



@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss'],
})

export class CheckListComponent implements OnInit {

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      //ne pas retirer l'élément de sa position initiale
      copyArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  dragging: any;

  public handleDragStart(event: CdkDragStart<object[]>): void {
    this.dragging = true;
    document.getElementById("stagiaire").classList.remove('drop');
    document.getElementById("stagiaire").classList.add('swing');
  }
  // public handleDragMove(event: CdkDragMove<Recipe[]>): void {
  //   document.getElementById("stagiaire").classList.remove('drop');
  //   document.getElementById("stagiaire").classList.add('swing');
  // }
  
  public handleDragEnd(event: CdkDragEnd<object[]>): void {
    // alert('Distance: X = ' + event.distance.x + ' Y = ' + event.distance.y + '\n' + 'Position: X = ' + event.dropPoint.x + ' Y = ' + event.dropPoint.y);
    
    // Modifie les classes pour l'animations
    document.getElementById("stagiaire").classList.remove('swing');
    document.getElementById("stagiaire").classList.add('drop');

    
    let hoveredCase : any = document.getElementsByClassName('cdk-drop-list-dragging')[0];    
   
    if(this.modales[hoveredCase.id.split('-')[1]]) {
      this.selectedModale = this.modales[hoveredCase.id.split('-')[1]];
      this.afficherModale();
    }
  }

  @Input()
  
  public id_activitie:any

  // utilisé pour afficher le contenu de la modale
  public selectedModale : any

  // Nécéssaire pour le drag and drop et l'affichage du toque
  public Toque = ['']

  public Palais = ['']

  // JSON des modales
  public modales = [
    {
      "id":"chefJur",
      "titre":"Chef de juridiction",
      "question" : "Comment dois-je l'appeler ?",
      "reponse" : "Le stagiaire ne connaît pas forcément les codes de la juridiction À vous d'évoquer les usages ayant cours :",
      "italic" : "monsieur le procureur, madame la bâtonnière, etc."
    },
    {
      "id":"service",
      "titre":"Service",
      "question" : "Y suis-je identifié ? Et moi, je les identifie ?",
      "reponse" : "Il est indispensable de faire le tour du service avec le stagiaire et lui présenter toutes les personnes présentes et leurs fonctions."
    },
    {
      "id":"greffe",
      "titre":"Greffe correctionnel",
      "question" :"Mais où sont les dossiers de l'audience correctionnelle ?",
      "reponse" : "Le stagiaire doit pouvoir localiser les services utiles et connaitre leur fonctionnement."
    },
    
    {
      "id":"bureauMds",
      "titre":"Maître de stage",
      "question" :"Dois-je vouvoyer mon maître de stage ?",
      "reponse" : "Le stagiaire se posera forcément la question ! Le mieux est de vous positionner dès le début du stage."
    },
    {
      "id":"pasPerdus",
      "titre":"Salle des pas perdus",
      "question" : "Aaaaaaaah !",
      "reponse" : "Vous avez perdu votre stagiaire",
      "image":"/assets/check-list/toque-pas-perdus.gif"
    },
    {
      "id":"audience",
      "titre":"Salle d'audience",
      "question" : "Et où aura lieu la prochaine audience ?",
      "reponse":"Le stagiaire doit être en mesure de savoir où se déroulent les actes qu'il aura à prendre."
    },
  ]

  constructor() {
    
  }

  ngOnInit() {
    
  }

  afficherModale(){
    let modale:any = document.getElementById("modale");
    modale.style.opacity = 1;
  }
  masquerModale(){
    let modale:any = document.getElementById("modale");
    modale.style.opacity = 0;

    setTimeout(()=> {
      this.selectedModale = null;
    },300)
  } 
}

