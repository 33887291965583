import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  private user:any;

  constructor(private router:Router,private userService:UsersService) { }

  async canActivate(){    
    if(!localStorage.getItem('user')){
      localStorage.clear();
      this.router.navigate(['/']);
      return false;
    }


    //Vérification du JWT
    if(localStorage.getItem('jwt')){
      this.user = await this.userService.get_user_by_jwt();
      if(this.user.id){
        localStorage.setItem("user",JSON.stringify(this.user));
        return true;
      }
      else{
        localStorage.clear();
        this.router.navigate(['/']);
        return false;
      }
    }
  }
}
