import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminGuardService } from './shared/service/adminGuard/admin-guard.service';
import { AuthGuard } from './shared/service/authGuard/auth-guard.service';

const routes: Routes = [
  // Public
  {
    path:"",
    redirectTo:"accueil",pathMatch:"full"
  },
  {
    path:"accueil",
    loadChildren: () => import("./modules/public/accueil/accueil.module").then(m =>m.AccueilModule)
  },
  {
    path:"auto_connect/:jwt/:first_access",
    loadChildren: () => import("./modules/public/auto-connect/auto-connect.module").then(m =>m.AutoConnectModule),
  },
  {
    path:"auto_connect/:error",
    loadChildren: () => import("./modules/public/auto-connect/auto-connect.module").then(m =>m.AutoConnectModule),
  },
  // Authentifié
  {
    path:"app",
    loadChildren: () => import("./modules/logged/logged.module").then(m =>m.LoggedModule),
    canActivate: [AuthGuard]
  }
  // Admin
  ,{
    path:"admin",
    loadChildren: () => import("./modules/admin/admin.module").then(m =>m.AdminModule),
    canActivate : [AdminGuardService]
  }
  // 404
  ,{
    path : '**', pathMatch:'full',
    loadChildren: () => import("./modules/public/error/error.module").then(m =>m.ErrorModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
