import { HttpClient ,HttpErrorResponse , HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  private env:any;
  private reqHeader:any;
  private user:any;
  private activities:any;

  constructor(private http:HttpClient,private router:Router) {
    this.env = this.setENV();
    //init req header
    if(localStorage.getItem('jwt')){
      this.reqHeader = new HttpHeaders({
        'Content-Type' : 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem('jwt'),
      });
    }
   }



   async get(){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/activities/',{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        this.activities = response;
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async get_by_id(id:any){
    return new Promise((resolve,reject)=>{
      this.http.get('/api/activities/'+id,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }

  async remove(id:any){
    return new Promise((resolve,reject)=>{
      this.http.delete("/api/activities/"+id,{headers : this.reqHeader}).toPromise().then(async (response:any)=>{
        resolve(response);
      }).catch((err:HttpErrorResponse)=>{
        if(err.status == 401 || err.status == 403) this.disconnect_user();
      })
    })
  }
 

  async disconnect_user(){
    localStorage.clear();
    alert("activities");
    window.location.href = "/navigation";
  }

  setENV() {
    if (window.location.hostname.indexOf("app") != -1) {
      return "app";
    } else if (window.location.hostname.indexOf("test") != -1) {
      return "test";
    } else if (window.location.hostname.indexOf("localhost") != -1) {
      return "local";
    } 
  }
}
